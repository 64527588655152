import React, { useState } from "react";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";
import Loader from "../../../components/loader";
import { useNavigate } from "react-router-dom";
import { States } from "../../../components/states";
import { auth, db } from "../../../config/FirebaseConfig";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import loginImg from "../../../assets/orgsignup.json";
import AnimateImg from "../../../components/animations/AnimateImg";
import TermsConditions from "../../../components/termsConditions";
import { termsList } from "../../../components/termsConditions/termsList";
import venmoImg from "../../../assets/images/venmo.png";
import quickpayImg from "../../../assets/images/quickpay.png";
import checkImg from "../../../assets/images/check.png";

const AffiliateProfile = () => {
  const [paymentMethod, setPaymentMethod] = useState("venmo");
  const [authCred, setAuthCred] = useState({
    firstName: "",
    lastName: "",
    paymentId: "",
    phoneNumber: "",
    email: "",
    paymentMethod: paymentMethod,
    address: "",
    city: "",
    zipCode: "",
    state: "",
  });
  const [termsModal, setTermsModal] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value });
  };
  const getCount = async () => {
    let acount = 0;
    const querySnapshot = await getDocs(collection(db, "userCount"));
    querySnapshot.forEach((doc) => {
      const { count } = doc.data();
      acount = count + 1;
      updateCount(count + 1);
    });
    return acount;
  };
  const updateCount = async (count) => {
    const countDocRef = doc(collection(db, "userCount"), "count");
    await updateDoc(countDocRef, { count: count });
  };
  const handleUpdateProfile = async () => {
    if (
      authCred.firstName === "" ||
      authCred.lastName === "" ||
      authCred.paymentId === ""
    ) {
      setError(true);
      setMessage("Please fill all the fields");
      setLoading(false);
      return;
    }
    if (accepted) {
      setLoading(true);
      const count = await getCount();
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          let hasAffId = false;
          let affid = "";
          if (docSnap.exists()) {
            const data = docSnap.data();
            hasAffId = data.hasOwnProperty("affid");
            affid = data.affid;
          }

          if (paymentMethod === "check") {
            await updateDoc(docRef, {
              profile: true,
              affid: hasAffId
                ? affid
                : `${authCred.firstName
                    .slice(0, 1)
                    .toLowerCase()}${authCred.lastName
                    .slice(0, 1)
                    .toLowerCase()}${count}`,
              uid: user.uid,
              firstName: authCred.firstName,
              lastName: authCred.lastName,
              phoneNumber: localStorage.getItem("phone")
                ? localStorage.getItem("phone")
                : authCred.phoneNumber,
              paymentMethod: "check",
              account: authCred.paymentId,
              city: authCred.city,
              address: authCred.address,
              state: authCred.state,
              zipCode: authCred.zipCode,
              joinedCampaigns: [],
            });
          } else {
            await updateDoc(docRef, {
              profile: true,
              affid: hasAffId
                ? affid
                : `${authCred.firstName
                    .slice(0, 1)
                    .toLowerCase()}${authCred.lastName
                    .slice(0, 1)
                    .toLowerCase()}${count}`,
              uid: user.uid,
              firstName: authCred.firstName,
              lastName: authCred.lastName,
              phoneNumber: localStorage.getItem("phone")
                ? localStorage.getItem("phone")
                : authCred.phoneNumber,
              paymentMethod: paymentMethod,
              account: authCred.paymentId,
              joinedCampaigns: [],
            });
          }
          setLoading(false);
          localStorage.setItem("profile", true);
          localStorage.setItem(
            "affId",
            localStorage.getItem("affId")
              ? localStorage.getItem("affId")
              : `${authCred.firstName
                  .slice(0, 1)
                  .toLowerCase()}${authCred.lastName
                  .slice(0, 1)
                  .toLowerCase()}${count}`
          );
          navigate("/campaign");
        }
      } catch (error) {
        setLoading(false);
        setError(true);
        setMessage(error.message);
      }
    } else {
      setTermsModal(true);
    }
  };

  const handleOpenLink = () => {
    if (paymentMethod === "paypal") {
      window.open("https://www.paypal.com/pk/home", "_blank");
    } else if (paymentMethod === "venmo") {
      window.open("https://venmo.com/", "_blank");
    } else if (paymentMethod === "quickpay") {
      window.open("https://quickpay.net/", "_blank");
    }
  };
  const handleLogout = () => {
    auth.signOut().then(() => {
      localStorage.clear();
      navigate("/affLogin");
    });
  };
  return (
    <div className="md:h-screen md:overflow-hidden md:flex-row flex-col-reverse flex gap-5">
      <div className="flex flex-col gap-4 height xl:w-[55%] md:w-[55%] w-full lg:px-32 md:px-20 px-9 py-10 md:py-0 text-white  justify-center bg-[#283a99]">
        <div className="flex md:flex-row flex-col-reverse justify-between md:items-center">
          <span className="md:text-2xl text-xl font-bold">
            Select Payment Method
          </span>
        </div>

        <div className=" mx-auto img w-auto  md:hidden flex">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>

        <div className="flex flex-row gap-4 justify-between ">
          <span
            onClick={() => setPaymentMethod("venmo")}
            className={`flex flex-1 items-center justify-center cursor-pointer text-black text-xl rounded-[8px] p-[10px] border bg-${
              paymentMethod === "venmo" ? "[#ffffff]" : "[#ffffffaf]"
            }`}
          >
            VENMO
          </span>
          <span
            onClick={() => setPaymentMethod("quickpay")}
            className={`flex flex-1 items-center justify-center cursor-pointer text-black text-xl rounded-[8px] p-[10px] border bg-${
              paymentMethod === "quickpay" ? "[#ffffff]" : "[#ffffffaf]"
            }`}
          >
            QUICKPAY
          </span>
          <span
            onClick={() => setPaymentMethod("paypal")}
            className={`flex flex-1 items-center justify-center cursor-pointer text-black text-xl rounded-[8px] p-[10px] border bg-${
              paymentMethod === "paypal" ? "[#ffffff]" : "[#ffffffaf]"
            }`}
          >
            PAYPAL
          </span>
          <span
            onClick={() => setPaymentMethod("check")}
            className={`flex flex-1 items-center justify-center cursor-pointer text-black text-xl rounded-[8px] p-[10px] border bg-${
              paymentMethod === "check" ? "[#ffffff]" : "[#ffffffaf]"
            }`}
          >
            CHECK
          </span>
        </div>

        {/* First Name and LAst Name */}
        <div className="flex sm:flex-row flex-col justify-between gap-4">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              value={authCred.firstName}
              name="firstName"
              type="text"
              id="input-group-1"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="First Name"
            />
          </div>

          <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              value={authCred.lastName}
              name="lastName"
              type="test"
              id="input-group-2"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="Last Name"
            />
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-between gap-4">
          <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              {paymentMethod === "paypal" && (
                <i
                  style={{ color: "white", fontSize: "20px" }}
                  className="fa fa-paypal"
                  aria-hidden="true"
                ></i>
              )}
              {paymentMethod === "venmo" ? (
                <img className="w-[20px]" src={venmoImg} />
              ) : paymentMethod === "quickpay" ? (
                <img className="w-[20px]" src={quickpayImg} />
              ) : paymentMethod === "check" ? (
                <img className="w-[20px]" src={checkImg} />
              ) : null}
            </div>
            <input
              onChange={onChange}
              name="paymentId"
              type="text"
              id="input-group-6"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder={
                paymentMethod === "paypal"
                  ? "Paypal ID"
                  : paymentMethod === "venmo"
                  ? "Venmo ID"
                  : paymentMethod === "quickpay"
                  ? "QuickPay ID"
                  : "Check ID"
              }
            />
          </div>

          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-phone-square"
                aria-hidden="true"
              ></i>
            </div>
            {localStorage.getItem("phone") ? (
              <input
                onChange={onChange}
                value={authCred.email}
                name="email"
                type="email"
                id="input-group-5"
                className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="Your Email Address"
              />
            ) : (
              <input
                onChange={onChange}
                value={authCred.phoneNumber}
                name="phoneNumber"
                type="text"
                id="input-group-5"
                className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="Phone Number"
              />
            )}
          </div>
        </div>
        {paymentMethod !== "check" && (
          <span className=" sm:block hidden text-sm ">
            Don't have a{" "}
            {paymentMethod === "venmo"
              ? "Venmo"
              : paymentMethod === "paypal"
              ? "Paypal"
              : "QuickPay"}{" "}
            Account?{" "}
            <span
              onClick={handleOpenLink}
              className="cursor-pointer hover:text-slate-300 font-bold underline "
            >
              Create One
            </span>{" "}
          </span>
        )}

        {paymentMethod === "check" && (
          <>
            <div className="flex sm:flex-row flex-col justify-between gap-4">
              <textarea
                onChange={onChange}
                value={authCred.address}
                name="address"
                id="address"
                className="bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 resize-none h-20 dark:placeholder-gray-400 dark:text-white "
                placeholder="Address"
              ></textarea>
            </div>

            <div className="flex sm:flex-row flex-col justify-between gap-4">
              <div className="relative w-full ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <i
                    style={{ color: "white", fontSize: "20px" }}
                    className="fa fa-building"
                    aria-hidden="true"
                  ></i>
                </div>
                <input
                  onChange={onChange}
                  value={authCred.city}
                  name="city"
                  type="text"
                  id="input-group-8"
                  className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  placeholder="City"
                />
              </div>
              <div className="relative w-full ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <i
                    style={{ color: "white", fontSize: "20px" }}
                    className="fa fa-code-fork"
                    aria-hidden="true"
                  ></i>
                </div>
                <input
                  onChange={onChange}
                  value={authCred.zipCode}
                  name="zipCode"
                  type="text"
                  id="input-group-10"
                  className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  placeholder="Zip Code"
                />
              </div>
            </div>
            <div className="flex sm:flex-row flex-col justify-between gap-4">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                  <i
                    style={{ color: "white", fontSize: "20px" }}
                    className="fa fa-map-marker"
                    aria-hidden="true"
                  ></i>
                </div>
                <select
                  onChange={onChange}
                  name="state"
                  className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  "
                >
                  <option value="" disabled selected>
                    Select State
                  </option>
                  {States.map((state, index) => {
                    return (
                      <option className="text-black" key={index} value={state}>
                        {state}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}

        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={handleUpdateProfile}
            className="p-3 bg-white text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
          >
            Update Profile
          </button>
        )}
        <button
          onClick={handleLogout}
          className="p-3 bg-white text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
        >
          Logout
        </button>
      </div>

      <div className="md:flex hidden xl:flex-1 md:flex-[0.8] items-center justify-center">
        <div className=" xl:w-5/6 md:flex hidden">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>
        <div className=" w-5/6 md:hidden flex">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>
      </div>

      {error && (
        <GeneralMsgModal
          message={message}
          heading={"Warning"}
          closeModal={() => {
            setError(false);
          }}
        />
      )}
      {termsModal && (
        <TermsConditions
          termsList={termsList}
          visible={termsModal}
          handleAccept={() => {
            setAccepted(true);
            setTermsModal(false);
          }}
          closeModal={() => {
            setTermsModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AffiliateProfile;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import human from "../../../assets/images/human.png";
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import AddCampaign from "../../../components/admin/addCampaign";
import AddSuperAdminCampaign from "../campaigns/AddSuperAdminCampaign";
const SuperAdminNavbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    auth.signOut().then(() => {
      localStorage.removeItem("auth");
      localStorage.removeItem("superAdmin");
      navigate("/");
    });
  };
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false);
  return (
    <>
      <aside
        id="default-sidebar"
        className={`fixed custom-scroll shadow-md top-0 left-0 z-40 md:w-64 h-screen w-16 overflow-x-hidden ${
          location.pathname === "/superadmin/campDetail" && "hidden"
        } `}
        aria-label="Sidebar"
      >
        <div className="h-full sm:px-3 px-2 py-4 overflow-y-auto bg-[#cbdaff] dark:bg-gray-800">
          <ul className="space-y-3 font-medium">
            <li
              onClick={() => {
                navigate("/superadmin");
              }}
              className=" cursor-pointer"
            >
              <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white">
                <span
                  className="font-bold text-center cursor-pointer md:block hidden sm:text-3xl text-xl text-[#283a99]"
                  style={{ fontFamily: "'Akaya Telivigala', cursive" }}
                >
                  Jewish Affiliate Network
                </span>
              </a>
            </li>
            <img
              src={human}
              alt=""
              className=" m-auto md:w-[40%] w-[85%] cursor-pointer"
            />

            <li
              onClick={() => {
                navigate("/superadmin");
              }}
              className=" pt-5 cursor-pointer"
            >
              <span
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin" ? "black" : "#626262"
                    }`,
                  }}
                  className="fa fa-pie-chart"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Dashboard
                </span>
              </span>
            </li>

            <li
              onClick={() => {
                navigate("/superadmin/raisers");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin/raisers" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin/raisers"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-users"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin/raisers"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Organizations
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                navigate("/superadmin/users");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin/users" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin/users"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-user-circle"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin/users"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Affiliates
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                setOpenAddCampaignModal(true);
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/addCampaign" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin/addCampaign"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-plus-square"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin/addCampaign"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Add Campaign
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                navigate("/superadmin/campaigns");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin/campaigns" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin/campaigns"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-handshake-o"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin/campaigns"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Campaigns
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                navigate("/superadmin/payments");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin/payments" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin/payments"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-money"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin/payments"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Payments Tracking
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                navigate("/superadmin/messages");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/superadmin/messages" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/superadmin/messages"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-commenting-o"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/superadmin/messages"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Messages
                </span>
              </a>
            </li>

            <li onClick={handleLogout} className="  cursor-pointer ">
              <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="flex-1 md:flex hidden ml-3 ">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>
      {openAddCampaignModal && (
        <AddSuperAdminCampaign
          superAdmin={true}
          closeModal={() => {
            setOpenAddCampaignModal(false);
          }}
        />
      )}
    </>
  );
};

export default SuperAdminNavbar;

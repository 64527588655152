import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  PhotoIcon,
  VideoCameraIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import help1 from "../../../assets/images/help1.jpeg";
import help2 from "../../../assets/images/help2.jpeg";
import { storage, auth, db } from "../../../config/FirebaseConfig";
import {
  collection,
  setDoc,
  updateDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import AdminModalSuccess from "../../modals/AdminModalSuccess";
import category1 from "../../../assets/images/category1.jpeg";
import category2 from "../../../assets/images/category2.jpeg";
import step1 from "../../../assets/images/step1.jpeg";
import step2 from "../../../assets/images/step2.jpeg";
import step3 from "../../../assets/images/step3.jpeg";
import hhlep from "../../../assets/images/hhelp.jpeg";
import Loader from "../../loader";

export default function EditCampaign({
  closeModal,
  toogleStatus,
  Nopending,
  item,
}) {
  const [open, setOpen] = useState(true);
  const [Img, setImg] = useState("");
  const [showImage, setShowImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [campData, setCampData] = useState({
    name: "",
    url: "",
    commission: "",
    description: "",
    username: "",
    phone:"",
    email:"",
    password: "",
    coverPhoto: "",
    category: "",
    video: "",
    tracking: "",
  });
  const cancelButtonRef = useRef(null);
  const [vid, setVid] = useState("");
  const [videoLoading, setvideoLoading] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const captureVideo = async (e) => {
    setvideoLoading(true);
    const videoFile = e.target.files[0];
    setVid(videoFile);

    const vidUrl = await handleMediaUpload(videoFile);
    setVideoLink(vidUrl);
    setvideoLoading(false);
  };
  const [category, setCategory] = useState("");
  const [method, setMethod] = useState("");

  const onChange = (e) => {
    setCampData({ ...campData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setData();
  }, []);

  const handleCategory = (cat) => {
    setCategory(cat);
  };
  const toogleMethod = (met) => {
    setMethod(met);
  };
  const setData = () => {
    if (item) {
      setCampData({
        name: item.name,
        url: item.url,
        commission: item.commission,
        description: item.description,
        category: item.category,
        tracking: item.tracking ? item.tracking : "",
        phone: item.phone,
        email: item.email,
      });
      setCategory(item.category);
    }
  };
  const [successModal, setSuccessModal] = useState(false);

  const handleUpdateCampaign = async () => {
    if (
      campData.name === "" ||
      campData.url === "" ||
      campData.commission === "" ||
      campData.description === "" ||
      campData.coverPhoto === "" ||
      category === "" ||
      method === ""
    ) {
      alert("Please fill all the fields");
      return;
    } else {
      if (videoLoading) {
        alert("Please wait while video is uploading");
      } else {
        setLoading(true);
        let imgArr = [];
        if (Img !== "") {
          //Adding Video

          // Use map instead of forEach to get an array of promises
          const uploadPromises = Img.map(async (item) => {
            return await handleImageUpload(item);
          });

          // Wait for all promises to resolve
          const downloadURIs = await Promise.all(uploadPromises);
          // Push the resolved values into imgArr
          imgArr.push(...downloadURIs);
        }
        if(imgArr.includes(null)){
          setLoading(false);
          return
        }
        await updateDoc(doc(db, "campaigns", `${item.campId}`), {
          ...campData,
          image: Img === "" ? item.image : imgArr,
          video: videoLink,
          status: Nopending ? item.status : "pending",
          category: category,
          method:
            method === 1 ? "Method 1" : method === 2 ? "Method 2" : "Manual",
          tracking: category === "Others" ? campData.tracking : "",
        });
        toogleStatus();
        setLoading(false);
        setSuccessModal(true);
      }
    }
  };
  const handleMediaUpload = async (media) => {
    const filename = `media_${Date.now()}.${media.type.split("/")[1]}`; // Extract file extension from MIME type

    const mediaRef = ref(storage, `campaigns/${filename}`);

    try {
      const uploadTask = uploadBytesResumable(mediaRef, media);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // You can use this progress information if needed
      });

      await uploadTask;

      const downloadURL = await getDownloadURL(mediaRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading media: ", error);
      return null;
    }
  };
  const closeAddModal = () => {
    closeModal();
    window.location.reload();
  };
  const handleImageUpload = async (Img) => {
    const filename = `image_${Date.now()}.jpg`;

    const imageRef = ref(storage, `campaigns/${filename}`);

    try {
      const uploadTask = uploadBytesResumable(imageRef, Img);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      });

      await uploadTask;

      const downloadURL = await getDownloadURL(imageRef);

      return downloadURL;
    } catch (error) {
      alert("Error uploading image: ");
      return null;
    }
  };

  const captureImage = (e) => {
    const imageFiles = e.target.files;
    const imageArray = Array.from(imageFiles);
    setImg(imageArray);

    // Set image url as URL Object
    let showImageArray = [];
    imageArray.forEach((item) => {
      showImageArray.push(URL.createObjectURL(item));
    });
    setShowImage(showImageArray);
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen();
          closeModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-2xl text-[#283a99] font-semibold "
                      >
                        Edit Campaign
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm max-w-2xl text-gray-500">
                          Seamlessly create and launch fresh campaigns on our
                          platform. This user-friendly form simplifies the
                          process, allowing you to expand your initiatives
                          effortlessly and engage with your audience
                          effectively.
                        </p>
                        <div className="flex flex-col gap-2">
                          <div className="mt-6 sm:col-span-4">
                            <label
                              htmlFor="name"
                              className="block text-left text-xl font-bold leading-6 text-gray-900"
                            >
                              Select Campaign Platform
                            </label>
                            <div className=" flex mt-4 justify-between gap-4 ">
                              <div
                                onClick={() => {
                                  handleCategory("Charidy Campaign");
                                }}
                                className={`flex ${
                                  category === "Charidy Campaign" &&
                                  "border border-[#283a99]"
                                } cursor-pointer bg-slate-100 rounded-xl flex-1 items-center justify-center`}
                              >
                                <img
                                  src={category1}
                                  alt="CategoryImage"
                                  className="w-[200] h-[200]"
                                />
                              </div>
                              <div
                                onClick={() => {
                                  handleCategory("Chesed Fund Campaign");
                                }}
                                className={`flex ${
                                  category === "Chesed Fund Campaign" &&
                                  "border border-[#283a99]"
                                } cursor-pointer bg-slate-100 rounded-xl flex-1 items-center justify-center`}
                              >
                                <img
                                  src={category2}
                                  alt="CategoryImage"
                                  className="w-[200] h-[200]"
                                />
                              </div>
                              <div
                                onClick={() => {
                                  handleCategory("Others");
                                }}
                                className={`flex ${
                                  category === "Others" &&
                                  "border border-[#283a99]"
                                } cursor-pointer bg-slate-100 rounded-xl flex-1 items-center justify-center`}
                              >
                                <div className="w-[200] h-[200]">
                                  <span className="font-bold text-2xl">
                                    Others
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:col-span-4">
                            <label
                              htmlFor="name"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Campaign Name
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-indigo-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-60 ">
                                <input
                                  type="text"
                                  name="name"
                                  id="name"
                                  className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 focus:outline-none placeholder:text-gray-400 sm:text-sm  sm:leading-6"
                                  placeholder="Campaign Name"
                                  value={campData.name}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:col-span-4">
                            <label
                              htmlFor="email"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Your Email
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-indigo-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-60 ">
                                <input
                                  type="email"
                                  name="email"
                                  id="email"
                                  className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 focus:outline-none placeholder:text-gray-400 sm:text-sm  sm:leading-6"
                                  placeholder="Email Address"
                                  value={campData.email}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="mt-6 sm:col-span-4">
                            <label
                              htmlFor="phone"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Your Phone Number
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-indigo-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-60 ">
                                <input
                                  type="number"
                                  name="phone"
                                  id="phone"
                                  className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 focus:outline-none placeholder:text-gray-400 sm:text-sm  sm:leading-6"
                                  placeholder="Phone Number"
                                  value={campData.phone}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-4">
                            <label
                              htmlFor="url"
                              className="block text-sm text-left font-medium leading-6 text-gray-900"
                            >
                              Campaign URL
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                <input
                                  type="text"
                                  name="url"
                                  id="url"
                                  className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:outline-none sm:leading-6"
                                  placeholder="Campaign URL"
                                  value={campData.url}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className=" sm:col-span-4">
                            <label
                              htmlFor="commission"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Commission
                            </label>
                            <div className="mt-2">
                              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                <input
                                  type="text"
                                  name="commission"
                                  id="commission"
                                  className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:outline-none sm:leading-6"
                                  placeholder="Commission"
                                  value={campData.commission}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          </div>
                          {category === "Others" && (
                            <div className=" sm:col-span-4">
                              <label
                                htmlFor="commission"
                                className="block text-left text-sm font-medium leading-6 text-gray-900"
                              >
                                Affiliate tracking method:
                              </label>
                              <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                                  <input
                                    type="text"
                                    name="tracking"
                                    id="tracking"
                                    required
                                    className="block flex-1 border-0 bg-transparent py-2 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm focus:outline-none sm:leading-6"
                                    placeholder="eg (aff=, utm_source=)"
                                    value={campData.tracking}
                                    onChange={onChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="col-span-full">
                            <label
                              htmlFor="description"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Description
                            </label>
                            <div className="mt-2">
                              <textarea
                                id="description"
                                name="description"
                                rows={3}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset px-2 ring-gray-300 placeholder:text-gray-400  sm:text-sm sm:leading-6"
                                placeholder="What's on your mind......"
                                defaultValue={""}
                                value={campData.description}
                                onChange={onChange}
                              />
                            </div>
                          </div>

                          <div className="col-span-full">
                            <label
                              htmlFor="cover-photo"
                              className="block text-left text-sm font-medium leading-6 text-gray-900"
                            >
                              Cover photo
                            </label>
                            {showImage === "" &&
                              item.image.map((imgItem, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={imgItem}
                                    className="w-[100%]"
                                  />
                                );
                              })}
                            {showImage === "" ? (
                              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                  <PhotoIcon
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    aria-hidden="true"
                                  />
                                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                      htmlFor="coverPhoto"
                                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                      <span>Upload a file</span>
                                      <input
                                        id="coverPhoto"
                                        multiple
                                        name="coverPhoto"
                                        type="file"
                                        className="sr-only"
                                        value={campData.coverPhoto}
                                        onChange={captureImage}
                                      />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                  </div>
                                  <p className="text-xs leading-5 text-gray-600">
                                    PNG, JPG, GIF up to 10MB
                                  </p>
                                </div>
                              </div>
                            ) : (
                              showImage.map((item, index) => {
                                return (
                                  <img
                                    key={index}
                                    src={item}
                                    className="w-[100%]"
                                  />
                                );
                              })
                            )}
                          </div>

                          {vid === "" ? (
                            <div className="col-span-full">
                              <label
                                htmlFor="video"
                                className="block text-left text-sm font-medium leading-6 text-gray-900"
                              >
                                Upload Video
                              </label>
                              <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                <div className="text-center">
                                  <VideoCameraIcon
                                    className="mx-auto h-12 w-12 text-gray-300"
                                    aria-hidden="true"
                                  />
                                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                    <label
                                      htmlFor="video"
                                      className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                      <span>Upload a file</span>
                                      <input
                                        id="video"
                                        name="video"
                                        type="file"
                                        className="sr-only"
                                        value={campData.video}
                                        onChange={captureVideo}
                                        accept="video/*"
                                      />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                  </div>
                                  <p className="text-xs leading-5 text-gray-600">
                                    MP4, AVI, MOV up to 10MB
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : videoLoading ? (
                            <Loader />
                          ) : (
                            <div className="col-span-full">Video Uploaded</div>
                          )}

                          <div className=" sm:col-span-4 mt-8">
                            <span className=" text-[26px] font-bold text-black">
                              Enable Donation Tracking
                            </span>
                          </div>

                          <div className=" sm:col-span-4 mb-4 ">
                            <span className=" text-sm text-gray-600">
                              The Jewish Affiliate Network proudly offers a
                              vital functionality – real-time donation tracking.
                              This sophisticated feature provides organizations
                              and affiliates with the ability to monitor their
                              fundraising progress and commission earnings.
                              There are two distinct methods for activating this
                              feature.
                            </span>
                          </div>
                          <span className=" text-[20px] font-bold text-black">
                            Select the method
                          </span>

                          {category !== "Others" && (
                            <div className="flex my-4 justify-between gap-5">
                              <span
                                onClick={() => {
                                  toogleMethod(1);
                                }}
                                className={`flex-1 ${
                                  method === 1
                                    ? "bg-[#283a99]"
                                    : "bg-[#d3d3d369]"
                                } ${
                                  method === 1 ? "text-white" : "text-black"
                                } p-3 rounded-lg text-center cursor-pointer`}
                              >
                                Method 1
                              </span>

                              <span
                                onClick={() => {
                                  toogleMethod(2);
                                }}
                                className={`flex-1 ${
                                  method === 2
                                    ? "bg-[#283a99]"
                                    : "bg-[#d3d3d34e]"
                                } ${
                                  method === 2 ? "text-white" : "text-black"
                                } p-3 rounded-lg text-center cursor-pointer`}
                              >
                                Method 2
                              </span>
                            </div>
                          )}
                          <div className="flex my-4 justify-between gap-5">
                            <span
                              onClick={() => {
                                toogleMethod(3);
                              }}
                              className={`flex-1 ${
                                method === 3 ? "bg-[#283a99]" : "bg-[#d3d3d369]"
                              } ${
                                method === 3 ? "text-white" : "text-black"
                              } p-3 rounded-lg text-center cursor-pointer`}
                            >
                              Manual Method
                            </span>
                          </div>
                          <div>
                            <span className=" text-sm text-gray-600">
                              {method === 1
                                ? "Grant Administrative Access to the Jewish Affiliate Network: By assigning administrative rights to the Jewish Affiliate Network on your account, our network gains comprehensive access to your data. Our utilization will be strictly confined to extracting data related to donations facilitated through our affiliate links. While our access extends to all your data, our usage is limited to necessary statistics only. If you prefer not to share your entire dataset, Method 2 might be more suitable"
                                : "Selective Data Sharing Guidance: In this method, we will assist you in sharing only the essential data required by our system to track affiliate donation statistics. This process will be executed on your computer, ensuring that the Jewish Affiliate Network does not access any additional data from your account."}
                            </span>
                          </div>

                          {category === "Chesed Fund Campaign" &&
                          method === 1 ? (
                            <div className=" flex flex-col gap-3">
                              <div className="flex flex-col gap-2">
                                <span className=" font-bold text-md text-center">
                                  Step 1
                                </span>
                                <img
                                  src={help2}
                                  alt="hwlpingImage"
                                  className="w-1/2 self-center"
                                />
                              </div>
                              <div className="flex flex-col gap-2">
                                <span className=" font-bold text-md text-center">
                                  Step 2
                                </span>
                                <img
                                  src={help1}
                                  alt="hwlpingImage"
                                  className="w-[70%] self-center"
                                />
                              </div>
                            </div>
                          ) : category === "Charidy Campaign" &&
                            method === 1 ? (
                            <div className=" flex flex-col gap-3">
                              <div className="flex flex-col gap-2">
                                <span className=" font-bold text-md text-center">
                                  Step 1
                                </span>
                                <img
                                  src={step1}
                                  alt="hwlpingImage"
                                  className=" self-center"
                                />
                              </div>
                              <div className="flex flex-col gap-2">
                                <span className=" font-bold text-md text-center">
                                  Step 2
                                </span>
                                <img
                                  src={step2}
                                  alt="hwlpingImage"
                                  className=" self-center"
                                />
                              </div>
                              <div className="flex flex-col gap-2">
                                <span className=" font-bold text-md text-center">
                                  Step 3
                                </span>
                                <img
                                  src={hhlep}
                                  alt="hwlpingImage"
                                  className=" self-center"
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <button
                        disabled={loading}
                        type="button"
                        className={`inline-flex sm:w-20 w-full  justify-center rounded-md bg-[#283a99] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#283a999c] sm:ml-3 cursor-${
                          loading ? "wait" : "pointer"
                        } `}
                        onClick={handleUpdateCampaign}
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setOpen(false);
                          closeModal();
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {successModal && (
              <AdminModalSuccess
                title={"Success"}
                message={"Campaign Updated Successfully"}
                closeAddModal={closeAddModal}
                closeModal={() => {
                  setSuccessModal(false);
                }}
              />
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";
import EmailVerificationModal from "../../../components/modals/EmailVerficationModal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import "../../../App.css";
import Loader from "../../../components/loader";
import loginImg from "../../../assets/orglogin.json";
import AnimateImg from "../../../components/animations/AnimateImg";
const AffiliateLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      navigate("/");
    }
  }, []);

  const [authCred, setAuthCred] = useState({ email: "", password: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signupOption, setSignupOption] = useState(1);
  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value });
  };
  const handleAffLogin = async () => {
    if (authCred.email === "" || authCred.password === "") {
      setError(true);
      setMessage("Please fill all the fields");
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        authCred.email,
        authCred.password
      );

      if (user) {
        // You can replace this code with a more efficient way to check if the user exists in the "users" collection
        const querySnapshot = await getDocs(collection(db, "users"));
        let userFound = false;
        let isUserHasProfile = false;
        let affId = "";
        querySnapshot.forEach((doc) => {
          if (doc.id === user.user.uid) {
            userFound = true;
            affId = doc.data().affid;
            if (doc.data().profile) {
              isUserHasProfile = true;
            }
          }
        });

        if (userFound) {
          if (!user.user.emailVerified) {
            setShowErrorModal(true);
            setLoading(false);
          } else {
            localStorage.setItem("auth", true);
            localStorage.setItem("uid", user.user.uid);
            localStorage.setItem("profile", isUserHasProfile);
            if (affId) {
              localStorage.setItem("affId", affId);
            }
            // localStorage.setItem("accessToken", user.user.accessToken);
            navigate("/");
          }
        } else {
          setMessage("User Not found in Affiliate Login");
          // alert('User not found in the "users" collection');
          setLoading(false);
          setError(true);
          // await auth().signOut();
        }
      } else {
        setLoading(false);
        setMessage("Check your Login Role");
        setError(true);
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setMessage("This email is not registered");
        setLoading(false);
        setError(true);
      } else if (error.code === "auth/wrong-password") {
        setMessage("Password is incorrect");
        setLoading(false);
        setError(true);
      } else {
        setMessage(error.message);
        setLoading(false);
        setError(true);
      }
    }
  };

  const resendVerificationEmail = async () => {
    try {
      // Send the verification email to the currently signed-in user
      await sendEmailVerification(auth.currentUser);
      // Show a success message or update UI accordingly
      console.log("Verification email sent successfully");
    } catch (error) {
      // Handle any errors here
      console.error("Error sending verification email:", error.message);
    }
  };

  const [phone, setPhone] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmedUser, setConfirmedUser] = useState("");
  const [vcode, setvcode] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const onPhoneChange = (value) => {
    setPhone(value);
  };
  const sendOTP = async () => {
    if (phone === "") {
      alert("Please enter phone number");
    } else {
      try {
        setOtpLoading(true);
        const recaptchav = new RecaptchaVerifier(auth, "recaptcha", {
          size: "invisible",
        });
        const confirmation = await signInWithPhoneNumber(
          auth,
          `+${phone}`,
          recaptchav
        );
        if (confirmation) {
          setIsConfirmed(true);
          setConfirmedUser(confirmation);
          setOtpLoading(false);
        }
      } catch (error) {
        // Handle the error gracefully here
        console.error("An error occurred while sending OTP:", error);
        // Optionally, you can show a user-friendly error message or perform other actions
      }
    }
  };

  const handleVerify = async () => {
    try {
      setVerificationLoading(true);
      const userr = await confirmedUser.confirm(vcode);
      if (userr) {
        const querySnapshot = await getDocs(collection(db, "users"));
        let userFound = false;
        let isUserHasProfile = false;
        let affId = "";
        querySnapshot.forEach((doc) => {
          if (doc.id === userr.user.uid) {
            userFound = true;
            affId = doc.data().affid;
            if (doc.data().profile) {
              isUserHasProfile = true;
            }
          }
        });
        if (userFound) {
          localStorage.setItem("auth", true);
          localStorage.setItem("uid", userr.user.uid);
          localStorage.setItem("profile", isUserHasProfile);
          localStorage.setItem("affId", affId);
          localStorage.setItem("phone", `+${phone}`);
          // localStorage.setItem("accessToken", user.user.accessToken);
          navigate("/");
        } else {
          setMessage("User Not found in Affiliate Login");
          // alert('User not found in the "users" collection');
          setLoading(false);
          setError(true);
          // await auth().signOut();
        }

        setVerificationLoading(false);
      }
    } catch (error) {
      setVerificationLoading(false);
      console.error("An error occurred while verifying OTP:", error);
    }
  };

  return (
    <div className="md:h-screen md:overflow-hidden md:flex-row flex-col-reverse flex gap-5">
      <div className="flex flex-col gap-4 height xl:w-[45%] md:w-[55%] w-full lg:px-32 md:px-20 px-9 py-10 md:py-0 text-white  justify-center bg-[#283a99]">
        <div className="flex md:flex-row flex-col-reverse justify-between md:items-center">
          <span className="text-white text-sm md:hidden block">
            Before Continue, Login First
          </span>
          <span className="md:text-4xl text-3xl font-bold">
          Influencer login
          </span>
          <i
            onClick={() => {
              navigate("/", { replace: true });
            }}
            style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            className="fa fa-home"
            aria-hidden="true"
          ></i>
        </div>

        <div className=" mx-auto img w-auto  md:hidden flex">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>

        <div className="flex w-[50%] gap-4 self-center flex-row justify-between">
          <span
            onClick={() => {
              setSignupOption(1);
            }}
            className={`flex flex-1 cursor-pointer p-[10px] rounded-[8px] justify-center bg-${
              signupOption === 1 ? "white" : "whiteAlpha"
            } text-${signupOption === 1 ? "black" : "white"}`}
          >
            Email
          </span>
          <span
            onClick={() => {
              setSignupOption(2);
              setAuthCred({ email: "" });
            }}
            className={`flex flex-1 cursor-pointer p-[10px] rounded-[8px] justify-center bg-${
              signupOption === 2 ? "white" : "whiteAlpha"
            } text-${signupOption === 2 ? "black" : "white"}`}
          >
            Phone
          </span>
        </div>

        {signupOption === 1 && (
          <div className="relative mt-6">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="email"
              type="email"
              id="input-group-1"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="name@flowbite.com"
            />
          </div>
        )}

        {signupOption === 1 && (
          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-lock"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="password"
              type="password"
              id="input-group-2"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="**********"
            />
          </div>
        )}

        {signupOption === 2 && (
          <div className="flex flex-col gap-3">
            {isConfirmed ? (
              <>
                <input
                  onChange={(val) => {
                    setvcode(val.target.value);
                  }}
                  value={vcode}
                  name="vcode"
                  type="text"
                  id="input-group-3"
                  className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  placeholder="Enter Verification Code"
                />
                {verificationLoading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={handleVerify}
                    className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
                  >
                    Verify
                  </button>
                )}
              </>
            ) : (
              <>
                <PhoneInput
                  country={"us"}
                  inputStyle={{ color: "black" }}
                  value={phone}
                  onChange={onPhoneChange}
                />
                {otpLoading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={sendOTP}
                    className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
                  >
                    Send Otp
                  </button>
                )}
                <div id="recaptcha"></div>
              </>
            )}
          </div>
        )}

        {signupOption === 1 && (
          <div className="flex justify-end">
            <span
              onClick={() => {
                navigate("/forget");
              }}
              className="cursor-pointer hover:text-slate-300"
            >
              Forget Password?{" "}
            </span>
          </div>
        )}

        {signupOption === 1 && loading ? (
          <Loader />
        ) : (
          signupOption === 1 && (
            <button
              onClick={handleAffLogin}
              className="p-3 bg-white text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
            >
              Login
            </button>
          )
        )}

        <span className="mt-5 ">
          Don't have and account?{" "}
          <span
            onClick={() => {
              navigate("/affSignup");
            }}
            className="cursor-pointer hover:text-slate-300 underline "
          >
            SignUp
          </span>{" "}
        </span>
      </div>

      <div className="md:flex hidden xl:flex-1 md:flex-[0.8] items-center justify-center">
        <div className=" xl:w-5/6 md:flex hidden">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>
        <div className=" w-5/6 md:hidden flex">
          <AnimateImg json={loginImg} width="100%" height="100%" />
        </div>
      </div>

      {error && (
        <GeneralMsgModal
          message={message}
          heading={"Warning"}
          closeModal={() => {
            setError(false);
          }}
        />
      )}

      {showErrorModal && (
        <EmailVerificationModal
          closeModal={() => setShowErrorModal(false)}
          resendVerification={resendVerificationEmail}
        />
      )}
    </div>
  );
};

export default AffiliateLogin;

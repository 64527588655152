import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "../../../config/FirebaseConfig";
import { updateDoc, doc } from "firebase/firestore";
import EditCampaign from "../editCampaign.js";
import CharidyCampaign from "../../backend/CharidyCampaign.js";
import ChesedCampaign from "../../backend/chesedCampaign.js";
import AdminPaymentMethod from "../../modals/AdminPaymentMethod.js";

const CampaignCard = ({ item, getCampaignData }) => {
  const [Status, setStatus] = useState(item.status);
  const navigate = useNavigate();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAdminPaymentModal, setOpenAdminPaymentModal] = useState(false);
  const toogleStatus = () => {
    setStatus("pending");
  };
  const handleEndCampaign = async () => {
    try {
      await updateDoc(doc(db, "campaigns", item.campId), {
        status: "ended",
      });
      getCampaignData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAdminCampaignDetail = () => {
    localStorage.setItem("item", JSON.stringify(item));
    if (item.excel) {
      localStorage.setItem("excel", item.excel);
    }
    localStorage.removeItem("isSuperAdmin");
    navigate("/admin/campDetail", { state: { item: item } });
  };

  const [showEditModal, setShowEditModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (!user) {
            console.log("User not authenticated.");
            // You might want to handle this case, e.g., redirect to login.
            return;
          }

          const idToken = await user.getIdToken();

          const campaignUrl = item.url;
          // Ensure that campDataItem.url has a valid value
          console.log(item.method);

          if (
            item.category === "Chesed Fund Campaign" &&
            item.method !== "Manual"
          ) {
            const data = {
              campaignUrls: campaignUrl,
            };
            const response = await fetch(
              `https://us-central1-jewish-affiliate-network.cloudfunctions.net/calculateSum`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
          } else if (
            item.category === "Charidy Campaign" &&
            item.method !== "Manual"
          ) {
            const response = await fetch(
              `https://us-central1-jewish-affiliate-network.cloudfunctions.net/charidyFunction?url=${campaignUrl}`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
              }
            );
          }
        });

        // Clean up the observer on component unmount
        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error reporting or display to the user
      }
    };

    fetchData();
  }, [item]);
  return (
    <div
      className="flex flex-col relative overflow-hidden  justify-between mb-10 bg-white  rounded-md"
      style={{ boxShadow: "0px 5px 7px 0px rgb(114 114 114 / 25%)" }}
    >
      {localStorage.getItem('superAdmin') && <div
        onClick={() => {
          setShowEditModal(true);
        }}
        className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-125 bg-[#f4f1f1] absolute right-2 rounded-md p-2 items-center justify-center top-3 "
      >
        <i
          style={{ margin: "0px" }}
          className="fa fa-pencil-square-o"
          aria-hidden="true"
        ></i>
      </div>}
      <div className="flex justify-between h-[100%] flex-col">
        <div
          className=" bg-cover bg-center bg-no-repeat h-[40vh]"
          style={{ backgroundImage: `url(${item.image[0]})` }}
        />

        <div className="flex px-4 flex-col gap-2 my-10">
          <div className="flex items-center justify-between gap-2">
            <span className="text-[20px] text-[#283a99] font-semibold">
              {item.name}
            </span>
          </div>

          <span className="text-gray-500">Commission: {item.commission}%</span>
          <span className=" text-green-500">
            Collected: ${" "}
            {item.category === "Chesed Fund Campaign" ? (
              <ChesedCampaign userFriendlyLink={item.url} />
            ) : item.category === "Charidy Campaign" ? (
              <CharidyCampaign url={item.url} />
            ) : (
              "loading"
            )}
          </span>
          <span className=" text-red-500">Status: {Status}</span>
        </div>

        {item.method ? (
          <div className=" flex gap-4 my-5 flex-col px-4 items-center">
            <button
              onClick={handleAdminCampaignDetail}
              className="bg-[#283a99] hover:bg-[#283b999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
            >
              View Details
            </button>
            {Status === "disapproved" && (
              <button
                onClick={() => {
                  setOpenEditModal(true);
                }}
                className="bg-[#283a99] hover:bg-[#283b999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
              >
                Resubmit
              </button>
            )}
            {Status === "approved" && (
              <button
                onClick={handleEndCampaign}
                className="bg-[#283a99] hover:bg-[#283b999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
              >
                End Campaign
              </button>
            )}
          </div>
        ) : (
          <button
            onClick={() => {
              setOpenAdminPaymentModal(true);
            }}
            className="bg-[#283a99] mb-10 hover:bg-[#283b999c] px-3 w-[90%] m-auto py-2 text-white text-[17px] rounded-md"
          >
            Add Payment Method
          </button>
        )}
      </div>
      {openEditModal && (
        <EditCampaign
          toogleStatus={toogleStatus}
          closeModal={() => {
            setOpenEditModal(false);
          }}
          item={item}
        />
      )}
      {showEditModal && (
        <EditCampaign
          Nopending={true}
          toogleStatus={() => {}}
          closeModal={() => {
            setShowEditModal(false);
          }}
          item={item}
        />
      )}
      {openAdminPaymentModal && (
        <AdminPaymentMethod
          item={item}
          closeModal={() => {
            setOpenAdminPaymentModal(false);
          }}
          updateDone={() => {
            window.location.reload();
          }}
        />
      )}
    </div>
  );
};

export default CampaignCard;

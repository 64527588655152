import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LoginChoiceBox from "../login/LoginChoiceBox";
import { useNavigate } from "react-router-dom";
export default function SignupModal({ closeSignupModal }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen();
          closeSignupModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#d6e1fc] sm:p-10 p-3 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-slate-200 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-[24px] font-semibold  text-[#000713]"
                      >
                         Register/Login as organization
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <div className="flex sm:flex-row flex-col justify-between sm:gap-5">
                    <div
                    className="flex-1"
                      onClick={() => {
                        navigate("/orgSignup", { state: { key: "Organization" } });
                      }}
                    >
                      <LoginChoiceBox
                        icon={
                          <i
                            style={{ fontSize: "40px", color: "#283a99" }}
                            className="fa fa-user"
                            aria-hidden="true"
                          ></i>
                        }
                        heading={"Signup"}
                        para={
                          "Organization Sign up"
                        }
                      />
                    </div>
                    <div
                    className="flex-1"
                      onClick={() => {
                        navigate("/orgLogin", {
                          state: { key: "Organization" },
                        });
                      }}
                    >
                      <LoginChoiceBox
                        icon={
                          <i
                            style={{ fontSize: "40px", color: "#283a99" }}
                            className="fa fa-sitemap"
                            aria-hidden="true"
                          ></i>
                        }
                        heading={"Signin"}
                        para={
                          
                          "Organization Sign in"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#283a99] hover:bg-[#283b99a4] p-3 text-white sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      closeSignupModal();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/FirebaseConfig";
import CharidyCampaign from "../backend/CharidyCampaign";
import ChesedCampaign from "../backend/chesedCampaign";

const CampaignBox = ({ item, joined, ended, affid, home }) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [item]);
  const fetchData = async () => {
    try {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (!user) {
          console.log("User not authenticated.");
          // You might want to handle this case, e.g., redirect to login.
          return;
        }

        const idToken = await user.getIdToken();

        const campaignUrl = item.url;
        // Ensure that campDataItem.url has a valid value

        if (item.category === "Chesed Fund Campaign") {
          const data = {
            campaignUrls: campaignUrl,
          };
          const response = await fetch(
            `https://us-central1-jewish-affiliate-network.cloudfunctions.net/calculateSum`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
        } else if (item.category === "Charidy Campaign") {
          const response = await fetch(
            `https://us-central1-jewish-affiliate-network.cloudfunctions.net/charidyFunction?url=${campaignUrl}`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      });

      // Clean up the observer on component unmount
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error reporting or display to the user
    }
  };

  const handleNavigate = () => {
    // set item in local storage
    localStorage.setItem("item", JSON.stringify(item));
    if (home) {
      localStorage.setItem("home", true);
    } else {
      localStorage.removeItem("home");
    }

    localStorage.setItem("affId", affid);
    if (joined) {
      localStorage.setItem("joined", true);
    }
    if (!joined && !ended) {
      localStorage.removeItem("joined");
    }
    if (ended) {
      localStorage.setItem("joined", true);
      localStorage.setItem("ended", true);
    }
    if (!ended) {
      localStorage.removeItem("ended");
    }
    var arrayString = JSON.stringify(item.joinedUsers);

    localStorage.setItem("joinedUsers", arrayString);
    localStorage.setItem("campId", item.campId);
    navigate("/campaignDetail", {
      state: {
        item: item,
        joined: joined,
        affid: affid,
        ended: ended,
      },
    });
  };
  const handleVisit = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div
      class="flex flex-col relative overflow-hidden  justify-between mb-10 bg-white  rounded-md"
      style={{ boxShadow: "0px 5px 7px 0px rgb(114 114 114 / 25%)" }}
    >
      <div
        className=" bg-cover bg-center bg-no-repeat h-[20vh]"
        style={{ backgroundImage: `url(${item.image})` }}
      />

      <div className="flex px-4 flex-col gap-2 my-10">
        <span className="text-[20px] text-[#283a99] font-semibold">
          {item.name.length > 50 ? item.name.slice(0, 50) + "..." : item.name}
        </span>

        <span className="text-gray-500">Commission: {item.commission}%</span>
        {/* <span className=" text-green-500">
          Collected: $
          {item.category === "Chesed Fund Campaign" ? (
            <ChesedCampaign userFriendlyLink={item.url} />
          ) : item.category === "Charidy Campaign" ? (
            <CharidyCampaign url={item.url} />
          ) : (
            "loading"
          )}
        </span> */}
      </div>

      <div className=" flex px-4 my-5 items-center">
        {home ? (
          <button
            onClick={() => {
              handleVisit(item.url);
            }}
            className="bg-[#283a99] hover:bg-[#283a999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
          >
            Visit Campaign
          </button>
        ) : (
          <button
            onClick={handleNavigate}
            className="bg-[#283a99] hover:bg-[#283a999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
          >
            View Details
          </button>
        )}
      </div>
    </div>
  );
};

export default CampaignBox;

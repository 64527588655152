import React, { useState } from "react";
import campaign from "../../assets/images/camp.jpg";
import AvailableCampaign from "../../components/campaign/AvailableCampaign";
import JoinedCampaign from "../../components/campaign/JoinedCampaign";
import EndedCampaign from "../../components/campaign/EndedCampaign";
const Campaign = () => {
  const [option, setOption] = useState("available");
  const [searchData, setSetsearchData] = useState("");
  const toogleOption = (key) => {
    setOption(key);
  };
  const onchange = (e) => {
    setSetsearchData(e.target.value);
  };
  return (
    <>
      <div
        class="flex flex-col py-20 p-10 "
        id="CampaignBg"
        style={{
          color: "white",
          backgroundImage: `url(${campaign})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="flex flex-col gap-1 items-center justify-center">
          <h1 class=" md:text-[4rem] text-[2rem] font-semibold">Campaigns</h1>
          <p class="text-center text-sm xl:w-[60%] lg:w-[80%]">
            Browse our extensive list of available campaigns, view campaign
            details, check out commissions, and take action by joining the ones
            that resonate with you. Empower your fundraising journey with just a
            few clicks!
          </p>

          {/* <div class="relative mt-5 sm:w-[40%] w-full ">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                class="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              name="searchData"
              value={searchData}
              onChange={onchange}
              type="search"
              id="default-search"
              class="block w-full p-4 pl-10 text-sm text-gray-900 focus:outline-none border border-gray-300 rounded-lg bg-gray-50"
              placeholder="Search Campaigns ..."
              required
            />
            <div className=" flex gap-4">
              {searchData === "" && (
                <button
                  type="submit"
                  class={`text-white absolute right-2.5 bottom-[0.25rem] bg-[#283a99] hover:bg-[#283a999c] focus:outline-none  font-medium rounded-lg text-sm px-4 py-2 `}
                >
                  Search
                </button>
              )}
            </div>
          </div> */}
        </div>
      </div>

      {option === "available" && (
        <AvailableCampaign
          home={!localStorage.getItem("auth")}
          option={option}
          toogleOption={toogleOption}
        />
      )}
      {option === "joined" && localStorage.getItem("auth") && (
        <JoinedCampaign option={option} toogleOption={toogleOption} />
      )}
      {option === "ended" && localStorage.getItem("auth") && (
        <EndedCampaign option={option} toogleOption={toogleOption} />
      )}
    </>
  );
};

export default Campaign;

import React, { useEffect } from "react";
import RaiserList from "../../components/raisers/RaiserList";
import { auth, db } from "../../../config/FirebaseConfig";
import { getDocs, collection, arrayRemove, doc, updateDoc } from "firebase/firestore";
import { useState } from "react";

const Raisers = () => {
  useEffect(() => {
    getRaisers();
  }, []);

  const [raiserData, setRaiserData] = useState([]);

  const getRaisers = async () => {
    let ar = [];
    const querySnapshot = await getDocs(collection(db, "raiser"));
    querySnapshot.forEach((doc) => {
      ar.push(doc.data());
    });
    setRaiserData(ar);
  };

  const approveRaiser =async (raisId)=>{
    const docRef = doc(db, "raiser", raisId);
    await updateDoc(docRef, {
        approved:true
    })
    getRaisers();
  }

  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[83%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" pl-5 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Active Organizations
        </span>
        <span className=" pl-5 max-w-5xl text-gray-600 my-5">
          Discover a diverse array of passionate individuals and organizations
          driving impactful campaigns on our platform. Explore their profiles,
          learn about their causes, and connect with those who inspire you to
          create positive change.
        </span>

        <div className=" sm:pr-10 s:pl-0 pl-5">
          <RaiserList approveRaiser={approveRaiser} raiserData={raiserData} />
        </div>
      </div>
    </div>
  );
};

export default Raisers;

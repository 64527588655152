import React from "react";
import organization from "../../../assets/images/organize.jpg";
import StrategyBox from "./StrategyBox";
import fund from "../../../assets/images/fundraiser.png";
import { motion } from "framer-motion";
import blur from "../../../assets/images/blur.jpg";
import stats from "../../../assets/Animation - 1703787843382.json";
import animated2 from "../../../assets/Animation - 1703964896339.json";
import { useNavigate } from "react-router-dom";
const WorkStrategy = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col relative gap-5 xl:max-w-[88rem] px-8 py-10 m-auto">
      <span className="text-4xl font-bold text-[#283a99] text-center mt-10">
        Simplifying Connections - Benefiting Everyone
      </span>
      <span className="xl:max-w-6xl m-auto text-center text-gray-600 mb-10">
        Our platform bridges the gap between organizations and social media influencers, providing a user-friendly
        interface that empowers advertisers to promote causes effectively. This approach drives more donations for
        organizations while helping influencers earn income.
      </span>
     

      {/* <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut", delay: 0 }}
      >
        <StrategyBox
          rounded
          reverse
          heading="Organizations"
          subheading={"Amplify Your Mission"}
          para1={
            "Organizations, leverage the ease of our platform to elevate your cause. Submit your campaign details to our site for a quick review and approval. Upon approval, we alert our network of dedicated advertisers about your campaign, opening doors to increased exposure and support."
          }
          para2={
            "Our streamlined process ensures your cause receives the attention it needs. Utilize our extensive network of motivated advertisers to propel your mission forward."
          }
          image={stats}
        />
      </motion.div>
      <img
        style={{
          top: "18%",
          mixBlendMode: "multiply",
          left: "27%",
          width: "55%",
          filter: "blur(5px)",
        }}
        src={blur}
        alt="blur"
        className="absolute z-0 xl:block hidden "
      />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut", delay: 0.1 }}
        className="mt-10"
      >
        <StrategyBox
          rounded
          heading="Advertisers"
          subheading={"Make an Impact and Earn"}
          para1={
            "Advertisers, seize the opportunity to support diverse and meaningful campaigns. Select from a range of causes, access the necessary media materials, create compelling promotional content, and share it across your chosen social media platforms. Your earnings begin as soon as donations are made through your links, allowing you to drive positive change and profit simultaneously"
          }
          para2={
            "By participating in these campaigns, you contribute to significant causes while enhancing your income. Use the power of social media to champion the campaigns you believe in, and together, let's forge a better world."
          }
          image={animated2}
        />
      </motion.div> */}
    </div>
  );
};

export default WorkStrategy;

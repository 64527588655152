import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/FirebaseConfig";
import { updateDoc, collection, deleteDoc, doc } from "firebase/firestore";
import EditCampaign from "../../../components/admin/editCampaign.js";
import CharidyCampaign from "../../../components/backend/CharidyCampaign.js";
import ChesedCampaign from "../../../components/backend/chesedCampaign.js";
import AdminModalSuccess from "../../../components/modals/AdminModalSuccess.js";
import AddTrackingMethod from "../AddTrackingMethod.js";
import UpdateSuperAdminCampaign from './UpdateSuperAdminCampaign.js'
const SuperAdminCampaignBox = ({
  item,
  approved,
  rejected,
  affid,
  ended,
  getCampaignData,
}) => {
  const navigate = useNavigate();
  const [showEditModal, setShowEditModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (!user) {
            console.log("User not authenticated.");
            // You might want to handle this case, e.g., redirect to login.
            return;
          }

          const idToken = await user.getIdToken();

          const campaignUrl = item.url;
          console.log(campaignUrl);
          // Ensure that campDataItem.url has a valid value

          if (
            item.category === "Chesed Fund Campaign" &&
            item.method !== "Manual"
          ) {
            const data = {
              campaignUrls: campaignUrl,
            };
            const response = await fetch(
              `https://us-central1-jewish-affiliate-network.cloudfunctions.net/calculateSum`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              }
            );
          } else if (
            item.category === "Charidy Campaign" &&
            item.method !== "Manual"
          ) {
            const response = await fetch(
              `https://us-central1-jewish-affiliate-network.cloudfunctions.net/charidyFunction?url=${campaignUrl}`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${idToken}`,
                  "Content-Type": "application/json",
                },
              }
            );
          }
        });

        // Clean up the observer on component unmount
        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error reporting or display to the user
      }
    };

    fetchData();
  }, [item]);

  const [trackingModalOpen, setTrackingModalOpen] = useState(false);

  const handleApprove = async () => {
    if (item.category === "Others") {
      setTrackingModalOpen(true);
    } else {
      try {
        await updateDoc(doc(db, "campaigns", item.campId), {
          status: "approved",
        });
        // getCampaignData();
        setMessage("Campaign Approved");
        setSuccessModal(true);
      } catch (excep) {
        console.log(excep);
      }
    }
  };
  const handleOnComplete = async (tracking) => {
    try {
      await updateDoc(doc(db, "campaigns", item.campId), {
        status: "approved",
        tracking: tracking,
      });
      // getCampaignData();
      setMessage("Campaign Approved");
      setSuccessModal(true);
    } catch (excep) {
      console.log(excep);
    }
  };

  const handleDisapprove = async () => {
    try {
      await updateDoc(doc(db, "campaigns", item.campId), {
        status: "disapproved",
      });
      // getCampaignData();
      setMessage("Campaign disapproved.");
      setSuccessModal(true);
    } catch (excep) {
      console.log(excep);
    }
  };
  const handleDelete = async () => {
    try {
      //Delete a campaign
      const documentRef = doc(db, "campaigns", item.campId);
      await deleteDoc(documentRef);
      setMessage("Campaign deleted successfully");
      setSuccessModal(true);
    } catch (excep) {
      console.log(excep);
    }
  };
  const toPage = () => {
    localStorage.setItem("item", JSON.stringify(item));
    localStorage.setItem("approved", approved ? true : false);
    localStorage.setItem("affId", affid);
    localStorage.setItem("ended", ended ? true : false);
    navigate("/superadmin/campaignDetail", {
      state: {
        item: item,
        approved: approved,
        affid: affid,
        ended: ended,
      },
    });
  };
  return (
    <div
      class="flex flex-col relative overflow-hidden  justify-between mb-10 bg-white  rounded-md"
      style={{ boxShadow: "0px 5px 7px 0px rgb(114 114 114 / 25%)" }}
    >
      <div
        onClick={() => {
          setShowEditModal(true);
        }}
        className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-125 bg-[#f4f1f1] absolute right-2 rounded-md p-2 items-center justify-center top-3 "
      >
        <i
          style={{ margin: "0px" }}
          className="fa fa-pencil-square-o"
          aria-hidden="true"
        ></i>
      </div>

      <div
        className=" bg-cover bg-center bg-no-repeat h-[40vh]"
        style={{ backgroundImage: `url(${item.image})` }}
      />

      <div className="flex px-4 flex-col gap-2 my-10">
        <span className="text-[20px] text-[#283a99] font-semibold">
          {item.name}
        </span>

        <span className="text-gray-500">
          Commission: {item.commission}%{item.category}
        </span>
        <span className=" text-green-500">
          Collected: $
          {item.category === "Chesed Fund Campaign" ? (
            <ChesedCampaign userFriendlyLink={item.url} />
          ) : item.category === "Charidy Campaign" ? (
            <CharidyCampaign url={item.url} />
          ) : (
            "loading"
          )}
        </span>
      </div>

      
        <div className=" flex flex-col my-4 px-4 items-center">
          <button
            onClick={toPage}
            className="bg-[#283a99] hover:bg-[#283a999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
          >
            View Details
          </button>
          {!rejected && !approved && (
            <div className=" flex flex-row w-[100%] justify-between mt-5 gap-5">
              <div className=" flex-1">
                <button
                  onClick={handleApprove}
                  className="bg-[#3fbf3f] w-[100%] hover:bg-[#3fbf3fbb] px-3  py-2 text-white text-[17px] rounded-md"
                >
                  Approve
                </button>
              </div>
              <div className=" flex-1">
                <button
                  onClick={handleDisapprove}
                  className="bg-[#ff4400a8] w-[100%] hover:bg-[#ff4400] px-3 py-2 text-white text-[17px] rounded-md"
                >
                  Disapprove
                </button>
              </div>
            </div>
          )}
          <button
            onClick={handleDelete}
            className="bg-[#ff0000e5] mt-3.5 hover:bg-[#ff00008f] px-3 w-full py-2 text-white text-[17px] rounded-md"
          >
            Delete Campaign
          </button>
        </div>
      

      {showEditModal && (
        <UpdateSuperAdminCampaign
          Nopending={true}
          toogleStatus={() => {}}
          closeModal={() => {
            setShowEditModal(false);
          }}
          item={item}
        />
      )}
      {successModal && (
        <AdminModalSuccess
          title={"Success"}
          message={message}
          closeAddModal={() => {
            window.location.reload();
          }}
          closeModal={() => {
            setSuccessModal(false);
          }}
        />
      )}
      {trackingModalOpen && (
        <AddTrackingMethod
          closeModal={() => {
            setTrackingModalOpen(false);
          }}
          onComplete={handleOnComplete}
        />
      )}
    </div>
  );
};

export default SuperAdminCampaignBox;

import React, { useEffect, useState } from "react";
import CampaignCard from "../../../components/admin/campaigns/CampaignCard";
import { auth, db } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Loading from "../../../components/animations/Loading";
const Campaigns = () => {
  const [campData, setCampData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCampaignData();
  }, []);
  const getCampaignData = async () => {
    let ar = [];

    setLoading(true);
    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const {
          campId,
          commission,
          description,
          image,
          isApproved,
          joinedUsers,
          name,
          raisId,
          url,
          status,
          email,
          phone,
          password,
          video,
          category,
          method,
          excel,
          tracking,
        } = doc.data();
        if (raisId === auth.currentUser.uid) {
          ar.push({
            campId,
            commission,
            description,
            image,
            isApproved,
            joinedUsers,
            name,
            raisId,
            url,
            phone,
            status,
            email,
            password,
            video,
            category,
            method,
            excel,
            tracking,
          });
        }
      });
    });

    setCampData(ar);
    setLoading(false);
    // console.log(ar);
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[70%] sm:w-[90%] w-[80%]  "
    >
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col">
          <span className=" lg:pl-5 pl-10 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
            Active Campaigns
          </span>

          {campData.length === 0 ? (
            <div className=" flex h-screen justify-center items-center flex-col">
              <i
                style={{ fontSize: "20px" }}
                className="fa text-black fa-handshake-o"
                aria-hidden="true"
              ></i>
              <span className="text-2xl font-bold text-center">
                No Campaigns Found
              </span>
            </div>
          ) : (
            <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 lg:pr-10 sm:pr-0 pr-5 lg:px-0 sm:px-10 my-5 gap-5">
              {campData.map((item, index) => {
                return (
                  <CampaignCard
                    getCampaignData={getCampaignData}
                    key={index}
                    item={item}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Campaigns;

import React, { useState } from "react";
import QRCode from "qrcode.react";
import "./QRCodeGenerator.css"; // Create a CSS file for styling

const QRCodeGenerator = ({ urlCamp }) => {
  const [url, setUrl] = useState(urlCamp); // State to store the URL
  const [qrCodeSize, setQRCodeSize] = useState(128);
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const handleDownload = () => {
    // Generate a download link for the QR code image
    const canvas = document.querySelector("canvas");
    const qrCodeImage = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeImage;
    downloadLink.download = "qr-code.png";
    downloadLink.click();
  };

  const handleShareOnFacebook = () => {
    // Share the URL on Facebook
    window.open(`https://www.facebook.com/sharer.php?u=${urlCamp}`);
  };

  const handleShareOnTwitter = () => {
    // Share the URL on Twitter
    window.open(`https://twitter.com/intent/tweet?url=${urlCamp}`);
  };

  const handleShareOnWhatsApp = () => {
    // Share the URL on WhatsApp
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(urlCamp)}`
    );
  };

  return (
    <div className="qr-code-generator">
      <div className=" flex md:flex-row flex-col gap-5 items-start justify-start">
        <QRCode value={urlCamp} size={210} />
        <div className=" grid grid-cols-1 xl:grid-cols-2 button-container xl:mt-5">
          <button onClick={handleDownload}>
            <i className="fa fa-download pr-3"></i> Download
          </button>
          <button style={{backgroundColor:'#1877F2'}} onClick={handleShareOnFacebook}>
            <i className="fa fa-facebook pr-3"></i> Share on Facebook
          </button>
          <button style={{backgroundColor:'#1DA1F2'}} onClick={handleShareOnTwitter}>
            <i className="fa fa-twitter pr-3"></i> Share on Twitter
          </button>
          <button style={{backgroundColor:'#25D366'}} onClick={handleShareOnWhatsApp}>
            <i className="fa fa-whatsapp pr-3"></i> Share on WhatsApp
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
